// Chakra imports
//import {
//  useColorModeValue,
//} from "@chakra-ui/react";
// Custom components
//import Card from "../../../../components/Card/Card";
//import CardBody from "../../../../components/Card/CardBody";
//import CardHeader from "../../../../components/Card/CardHeader";
import React, { useState } from "react";
import { gql,  useQuery } from "@apollo/client";
//import { useTranslation } from "react-i18next";
//import SettleTable from "../../../../components/Tables/SettleTable";
import './messageList.css';
import moment from "moment";
import SentMessageDialog from "./sentMessageDialog";

/*
const createTextSectionGql = gql`
  mutation createTextSection($input: CreateTextSectionInput!) {
    createTextSection(input: $input) {
      id
    }
  }
`;
*/
const GET_MESSAGES_GQL = gql`
query getMessagesByUser($input: getMessagesByUserInput!){
  getMessagesByUser(input: $input){
    id
    threadId
    messageSubject
    messageText
    dateRead
    createdAt
  }
}`;

/*

const deleteTextSectionGql = gql`
  mutation deleteTextSection($id: ID!) {
    deleteTextSection(id: $id)
  }
`;

const updateTextSectionGql = gql`
  mutation updateTextSection($input: UpdateTextSectionInput!) {
    updateTextSection(input: $input) {
      id
    }
  }
`;

const updateTextSectionSortingGql = gql`
  mutation updateTextSectionSorting($input: [UpdateTextSectionSortingInput]) {
    updateTextSectionSorting(input: $input) 
  }
`;
*/
export enum MessageFilter {
  ALL = 'ALL',  // eslint-disable-line
  UNREAD = 'UNREAD',  // eslint-disable-line
  READ = 'READ',  // eslint-disable-line
}


interface IProps {
  fotographerUserIds: [String] | null
  searchStr: String
  filter: MessageFilter
}

const MessageList: React.FC<IProps> = ({ fotographerUserIds, searchStr, filter }) => {
  //const textColor = useColorModeValue("gray.700", "white");
  const [showSentNewMessage, setShowSentNewMessage] = useState(false);
  //const [sentNewMessageType, setSentNewMessageType] = useState(MessageType.ORDER_BOOKED);
  //const toast = useToast();
  //const { t } = useTranslation();
  //const { isOpen, onOpen, onClose } = useDisclosure();
  //const { isOpen: isAddTextSectionDialogOpen, onOpen: onAddTextSectionDialogOpen, onClose: onAddTextSectionDialogClose } = useDisclosure();
  //const cancelRef = React.useRef();
  //const [createTextSection] = useMutation(createTextSectionGql);
  //const [deleteTextSection] = useMutation(deleteTextSectionGql);
  //const [updateTextSection] = useMutation(updateTextSectionGql);
  //const [updateTextSectionSorting] = useMutation(updateTextSectionSortingGql);

console.log('fotographerUserIds', fotographerUserIds);
  const { loading, error, data } = useQuery(GET_MESSAGES_GQL, {
    variables: { input: { userIds: fotographerUserIds, searchStr: searchStr, filter: filter } },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting message from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting message from server (2)</p>);
  }

  const closeSentNewMessage = () => {
    setShowSentNewMessage(false)
  }

  const handleCreateNewMessageClicked = () => {
    setShowSentNewMessage(true)
  }

/*
  const handleEditClick = (data: any) => {
    alert('not implemented');
  }
*/
  
  let tableData = JSON.parse(JSON.stringify(data.getMessagesByUser));


  return (
    <>

      <SentMessageDialog key={'OrderSentMessage'}  isVisible={showSentNewMessage} onClose={() => { closeSentNewMessage() }} />

    <div className="messageListContainer">
    <div className="createButton" onClick={handleCreateNewMessageClicked}>
      Opret ny besked
    </div>
      <table className="messageTable">
        <tr>
          <th className="messageHeadingSubject">EMNE</th>
          <th className="messageHeadingCreatedAt">OPRETTET</th>
        </tr>
        <tbody>
          {tableData.map((message) => (
            <tr key={message.id}>
              <td>{message.messageSubject}</td>
              <td style={{textAlign: 'end'}}>{moment(message.createdAt).format("DD-MM-YYYY HH:mm")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

export default MessageList;
