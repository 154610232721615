// Chakra imports
import { Select, Text, Grid, GridItem } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
//import CardBody from "components/Card/CardBody";
//import CardHeader from "components/Card/CardHeader";
import React, { useState } from "react";
import { RangeDatepicker } from "chakra-dayzed-datepicker"

/*
const dataPickerConfigs = {
  dateFormat: 'yyyy-MM-dd',
  dayNames: 'abcdefg'.split(''), // length of 7
  monthNames: 'ABCDEFGHIJKL'.split(''), // length of 12
  firstDayOfWeek: 2, // default is 0, the dayNames[0], which is Sunday if you don't specify your own dayNames,
}
*/

const DataSelectorCardDates = ({ updatefn, fromDate, toDate }) => {
  const [selectedDates, setSelectedDates] = useState([new Date(fromDate), new Date(toDate)]);


  const updateDates = (dates)=>{
    if (dates.length === 2 && dates[1] != null){
      const fromIsoDate = new Date(dates[0].getTime() - (dates[0].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
      const toIsoDate = new Date(dates[1].getTime() - (dates[1].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
      updatefn(fromIsoDate,toIsoDate);
    }
  }

  return (
    <Card p='16px' >

      <Grid templateColumns='repeat(4, 1fr)' gap={6} alignItems={'center'}>
        <GridItem>
          <Text>Vælg dato interval:</Text>
        </GridItem>
        <GridItem colSpan={3} className="calenderContainer">
          <RangeDatepicker
            selectedDates={selectedDates}
            onDateChange={(e)=>{setSelectedDates(e);updateDates(e);}}
            
            configs={{
              dateFormat: 'dd-MM-yyyy',
            }}
            propsConfigs={{
              dayOfMonthBtnProps: {
                defaultBtnProps: {
                  borderColor: "yellow.300",
                  _hover: {
                    background: 'yellow.400',
                  }
                },
                isInRangeBtnProps: {
                  background: "yellow.300",
                  color: "black",
                },
                selectedBtnProps: {
                  background: "yellow.300",
                  color: "black",
                }
              }
            }}
          />
        </GridItem>

       
        
      </Grid>
    </Card>
  );
};

export default DataSelectorCardDates;