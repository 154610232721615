// Chakra imports
import {
    Flex, useColorModeValue, Text, Grid, GridItem,
} from "@chakra-ui/react";
import { MultiSelect } from "react-multi-select-component";
import ProfileBgImage from "./../../../assets/img/ProfileBackground.png";
import { useState } from "react";
import Card from "./../../../components/Card/Card";
import CardBody from "./../../../components/Card/CardBody";
import Header from "./../../../components/Headers/Header";

//import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
//import { useHistory } from "react-router-dom";
//import moment from "moment";
import { useAuth } from "../../../auth-context/auth.context";
import MessageList, { MessageFilter } from "./components/messageList";
import MessageView from "./components/messageView";


const MESSAGES_DATA_GQL = gql`
query getData {
    users {
      id
      name
      userType
    }
}`

function Messages() {
    const userData = useAuth();

    let CalenderSelectedUser = localStorage.getItem("dashboard_calenderSelectedUser") ? localStorage.getItem("dashboard_calenderSelectedUser").split(',') : [localStorage.getItem("system_user_id")];
    const [calenderSelectedUser, setCalenderSelectedUser] = useState(CalenderSelectedUser);


    //const { isOpen: changeStatusIsOpen, onOpen: changeStatusOnOpen, onClose: changeStatusOnClose } = useDisclosure();
    //const history = useHistory();
    //const toast = useToast();

    //Colors
    //const textColor = useColorModeValue("gray.700", "white");
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    const { loading, error, data } = useQuery(MESSAGES_DATA_GQL, {

        manual: true,
        fetchPolicy: "no-cache"
    }
    );

    if (loading) {
        return <p></p>; //loading
    }
    if (error) {
        return <p>Error getting messages from server (1)</p>;
    }
    if (!data) {
        return <p>Error getting messages from server (2)</p>;
    }

    const filterUsers = () => {
        if (userData.user.userType === 'PHOTOGRAPHER') {
            return sortUsers(data.users.filter((user) => user.id === userData.user.id));
        }

        if (userData.user.userType === 'PHOTOGRAPHY_COMPANY_ADMIN') {

            return sortUsers(data.users.filter((user) => user.userType !== 'ADMIN'));
        }

        if (userData.user.userType === 'ADMIN') {
            return sortUsers(data.users);
        }
    }

    const sortUsers = (users) => {
        let sortedUsers = users.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });

        return sortedUsers;
    }

    const mappedUsers = filterUsers().map((user) => {
        return {
            value: user.id,
            label: user.name
        }
    });

    const getAllSelectedUsers = () => {
        let selectedUsers = [];
        for (let i = 0; i < calenderSelectedUser.length; i++) {
            let user = data.users.find((x) => x.id === calenderSelectedUser[i]);
            if (user) {
                selectedUsers.push({
                    value: user.id,
                    label: user.name
                })
            }
        }
        return selectedUsers
    }

    const handleUserChange = (selectedOptions) => {
        //get selected user ids
        let selectedUserIds = selectedOptions.map((option) => option.value);

        setCalenderSelectedUser(selectedUserIds);
        localStorage.setItem("dashboard_calenderSelectedUser", selectedUserIds.join(','))
    };

    const userDropdownLocalize = {
        "allItemsAreSelected": "Alle fotografer er valgt.",
        "clearSearch": "Ryd søgning",
        "clearSelected": "Ryd valgte",
        "noOptions": "Ingen valgmuligheder",
        "search": "Søg",
        "selectAll": "Vælg alle",
        "selectAllFiltered": "Vælg alle (Filtreret)",
        "selectSomeItems": "Vælg fotograf",
        "create": "Opret",
    }


    return (
        <div>
            <Flex direction="column" style={{ height: 'calc(100vh - 60px)' }} >
                <Header
                    backgroundHeader={ProfileBgImage}
                    backgroundProfile={bgProfile}
                    name={"Beskeder"}
                    description={'Her kan du kommunikere med dine kunder, sæglere, leverandører mm.'}
                />
                <Card px="0px" style={{ padding: '10px' }} height='70px' mb={{ lg: "26px" }}>

                    <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', alignItems: 'center' }}>
                        {['PHOTOGRAPHY_COMPANY_ADMIN', 'ADMIN'].includes(userData.user.userType) && (

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', widht: '400px' }}>
                                <Text style={{ width: '120px', fontSize: '14px', fontWeight: 'bold', paddingLeft: '14px', }}>Fotograf: </Text>

                                <MultiSelect
                                    options={mappedUsers}
                                    value={getAllSelectedUsers()}
                                    onChange={handleUserChange}
                                    labelledBy="Select"
                                    placeholder="Vælg bruger"
                                    className="userDropdown"
                                    overrideStrings={userDropdownLocalize}
                                />
                            </div>
                        )}
                    </div>

                </Card>
                <Grid templateColumns={{ sm: "repeat(6, 1fr)", xl: "repeat(6, 1fr)" }} style={{ height: '100%' }} gap={{ sm: "24px", xl: "24px" }} my="0px" mb={{ lg: "26px" }}>

                    <GridItem colSpan={2} >
                        <Card px="0px" height='100%'>
                            <CardBody>
                                <MessageList filter={MessageFilter.ALL} searchStr={""} fotographerUserIds={calenderSelectedUser}  />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem colSpan={4} >
                        <Card px="0px" height='100%'>
                            <CardBody>
                                <MessageView filter={MessageFilter.ALL} searchStr={""} fotographerUserIds={calenderSelectedUser} />
                            </CardBody>
                        </Card>
                    </GridItem>
                </Grid>
            </Flex>

        </div>
    );
}

export default Messages;
