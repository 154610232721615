import {
    Flex, Text, useColorModeValue, useToast,
    Icon,
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Button, Tabs, TabList, Tab, TabPanels, TabPanel, Input, Grid, Textarea
} from "@chakra-ui/react";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import { useState, useRef } from "react";
import { BsCircleFill } from "react-icons/bs";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import Editor from "../../../../components/Editor/Editor";
import { SubcontractorType } from "../../../../types";


const CREATE_SUBCONTRACTOR_ORDER = gql`
  mutation createSubcontractorOrder($input: CreateSubcontractorOrderInput!) {
    createSubcontractorOrder(input: $input) {
      id
    }
  }
`;

const GET_SUBCONTRACTORS = gql`
  query currentUser {
    currentUser {
      id
        photographyCompany {
            id
            subcontractors {
                id
                name
                email
                subcontractorType
                language
                bookingEmail
                isGlobal
                price
                priceType
                price2
                priceType2
            }
        }   
    }
}`

const GET_SUBCONTRACTOR_RENDERED_TEXTS = gql`
  query getSubcontractorRenderedMailText($id: ID!,$orderId: ID!) {
    getSubcontractorRenderedMailText(id: $id, orderId: $orderId) {
        mailSubject
        mailText
    }
}`

interface IProps {
    onClose: any,
    orderId: string,
    isOpen: boolean
}

const TextOrderDialog: React.FC<IProps> = ({ orderId, isOpen, onClose }) => {
    const textColor = useColorModeValue("gray.700", "white");
    const toast = useToast();
    const contractorBoxColor = useColorModeValue("gray.200", "white");
    const bgPrevButton = useColorModeValue("gray.100", "gray.100");
    const [activeBullets, setActiveBullets] = useState({
        subcontractorTab: true,
        messageTab: false,
    });
    const [selectedSubContractor, setSelectedSubContractor] = useState(null);

    const subcontractorTab = useRef<HTMLButtonElement>();
    const messageTab = useRef<HTMLButtonElement>();

    const [createOrder] = useMutation(CREATE_SUBCONTRACTOR_ORDER);
    const [getRenderedTexts, { data: dataRenderedTexts }] = useLazyQuery(GET_SUBCONTRACTOR_RENDERED_TEXTS, { fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });


    const { loading, error, data } = useQuery(GET_SUBCONTRACTORS);


    const setSubContractor = (subcontractorId) => {
        //find the subcontractor
        data.currentUser.photographyCompany.subcontractors.forEach((subcontractor) => {
            if (subcontractor.id === subcontractorId) {
                setSelectedSubContractor({ ...subcontractor });
                const input = {
                    id: subcontractorId,
                    orderId: orderId
                }
                getRenderedTexts({ variables: input }).then((data) => {
                    let tmp = { ...subcontractor };

                    tmp.mailText = data.data.getSubcontractorRenderedMailText.mailText;
                    tmp.mailSubject = data.data.getSubcontractorRenderedMailText.mailSubject;
                    setSelectedSubContractor(tmp);
                })
            }
        });
    }

    const updateData = (field: string, data: any) => {
        selectedSubContractor[field] = data;
        setSelectedSubContractor({ ...selectedSubContractor });
    };


    const updateText = (text) => {
        if (text !== "") {
            selectedSubContractor.mailText = text;
            setSelectedSubContractor({ ...selectedSubContractor });
        }
    };

    const handleHighlightTextChange = (text) => {
        if (text !== "") {
            selectedSubContractor.highlightText = text;
            setSelectedSubContractor({ ...selectedSubContractor });
        }
    }

    const sentMaterial = () => {
        let inputData = {} as any;
        inputData.orderId = orderId;
        inputData.subcontractorId = selectedSubContractor.id;
        inputData.mailSubject = selectedSubContractor.mailSubject;
        inputData.mailText = selectedSubContractor.mailText;
        if (selectedSubContractor.highlightText) {
            inputData.descriptionNote = selectedSubContractor.highlightText;
        }

        console.log('selectedSubContractor', selectedSubContractor)
        console.log('inputData', inputData)
        createOrder({ variables: { input: inputData } }).then(() => {

            toast({
                title: "Ordren er oprettet.",
                description: "",
                status: "success",
                duration: 2000,
                isClosable: true,
            })

            onClose();
        }).catch(() => {
            toast({
                title: "Ordren kunne ikke oprettes.",
                description: "Der opstod en fejl i oprettelsen af ordren.",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        });
    }


    if (loading) return <p></p>; //loading

    if (error) {
        return <p>Error getting subcontractors from server (1)</p>; //TODO: make error component
    }

    if (!data) {
        return <p>Error getting subcontractors from server (2)</p>;
    }

    const MediaProcessers = data.currentUser.photographyCompany.subcontractors.filter((subcontractor: any) => subcontractor.subcontractorType === SubcontractorType.AUTHER && subcontractor.bookingEmail.trim() !== "");


    const translatePriceType = (priceType) => {
        switch (priceType) {
            case 'PRICE_PER_FLOORPLAN':
                return 'plantegning';
            case 'PRICE_PER_IMAGE':
                return 'billede';
            case 'PRICE_PER_ORDER':
                return 'ordre';
            case 'NONE':
                return '';
            default:
                return '';
        }
    }

    return (

        <Modal onClose={onClose} size="xl" isOpen={isOpen} >
            <ModalOverlay />
            <ModalContent maxW="800px" bg={'#f9f9f9'}>
                <ModalHeader>Bestil tekster</ModalHeader>

                <ModalCloseButton />
                <ModalBody>


                    <Flex direction='column' style={{ width: '100%' }}>
                        <Flex
                            direction="column"

                            align="center"
                            pt={{ sm: "0px", md: "0px" }}
                        >
                            <Tabs variant="unstyled" mt="24px" width={'100%'}>
                                <TabList display="flex" justifyContent="center">
                                    <Tab ref={subcontractorTab}
                                        _focus={{ outline: "none" }}
                                        w={{ sm: "80px", md: "200px" }}
                                        onClick={() =>
                                            setActiveBullets({
                                                subcontractorTab: true,
                                                messageTab: false,
                                            })
                                        }
                                    >
                                        <Flex
                                            direction="column"
                                            justify="center"
                                            align="center"
                                            position="relative"
                                            _before={{
                                                content: "''",
                                                width: { sm: "80px", md: "200px" },
                                                height: "3px",
                                                bg: activeBullets.messageTab ? textColor : "gray.200",
                                                left: { sm: "12px", md: "64px" },
                                                top: { sm: activeBullets.subcontractorTab ? "6px" : "4px", md: null },
                                                position: "absolute",
                                                bottom: activeBullets.subcontractorTab ? "40px" : "38px",
                                                zIndex: -1,
                                                transition: "all .3s ease",
                                            }}
                                        >
                                            <Icon
                                                as={BsCircleFill}
                                                color={activeBullets.subcontractorTab ? textColor : "gray.300"}
                                                w={activeBullets.subcontractorTab ? "16px" : "12px"}
                                                h={activeBullets.subcontractorTab ? "16px" : "12px"}
                                                mb="8px"
                                            />
                                            <Text
                                                color={activeBullets.subcontractorTab ? { textColor } : "gray.300"}
                                                fontWeight={activeBullets.subcontractorTab ? "bold" : "normal"}
                                                display={{ sm: "none", md: "block" }}
                                            >
                                                Vælg leverandør
                                            </Text>
                                        </Flex>
                                    </Tab>

                                    <Tab
                                        ref={messageTab}
                                        _focus={{ outline: "none" }}
                                        w={{ sm: "80px", md: "200px" }}
                                        onClick={() =>
                                            setActiveBullets({
                                                subcontractorTab: true,
                                                messageTab: true,
                                            })
                                        }
                                    >
                                        <Flex direction="column" justify="center" align="center">
                                            <Icon
                                                as={BsCircleFill}
                                                color={activeBullets.messageTab ? textColor : "gray.300"}
                                                w={activeBullets.messageTab ? "16px" : "12px"}
                                                h={activeBullets.messageTab ? "16px" : "12px"}
                                                mb="8px"
                                            />
                                            <Text
                                                color={activeBullets.messageTab ? { textColor } : "gray.300"}
                                                fontWeight={activeBullets.messageTab ? "bold" : "normal"}
                                                transition="all .3s ease"
                                                _hover={{ color: textColor }}
                                                display={{ sm: "none", md: "block" }}
                                            >
                                                Send ordre
                                            </Text>
                                        </Flex>
                                    </Tab>
                                </TabList>
                                <TabPanels mt="24px" maxW={{ md: "90%", lg: "100%" }} mx="auto">
                                    <TabPanel>
                                        <Card>
                                            <CardHeader mb="40px">
                                                <Flex direction="column">
                                                    <Text
                                                        color={textColor}
                                                        fontSize="lg"
                                                        fontWeight="bold"
                                                        mb="3px"
                                                    >
                                                        Leverandør
                                                    </Text>
                                                    <Text color="gray.400" fontWeight="normal" fontSize="sm">
                                                        Vælg en leverandør
                                                    </Text>
                                                </Flex>
                                            </CardHeader>
                                            <CardBody>
                                                <Flex direction="column" w="100%">
                                                    <Grid
                                                        templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                                                        templateRows={{ md: "repeat(1, 1fr)" }}
                                                        gap="10px"
                                                    >
                                                        {MediaProcessers.length === 0 ? (

                                                            <Text fontSize="md" color={textColor} textAlign="center">
                                                                <i>Der er ikke oprettet nogen leverandører</i>
                                                            </Text>
                                                        ) : (
                                                            <>
                                                                {MediaProcessers.map((subcontractor: any) => {
                                                                    if (selectedSubContractor && selectedSubContractor.id === subcontractor.id) {
                                                                        return (
                                                                            <Card border={'2px solid'} borderColor={contractorBoxColor} bg={contractorBoxColor} key={subcontractor.id} width={'100%'} color="white" p="10px" borderRadius="5px" cursor="pointer" onClick={() => { setSubContractor(subcontractor.id); messageTab.current.click() }}>

                                                                                {subcontractor.isGlobal ? (
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
                                                                                        <Text fontSize="sm" fontWeight="bold" color={textColor}>
                                                                                            {subcontractor.name}
                                                                                        </Text>
                                                                                        <Text fontSize="sm" fontWeight="bold" color={'#adadad'}>
                                                                                            - {subcontractor.price} kr. pr. {translatePriceType(subcontractor.priceType)}
                                                                                        </Text>
                                                                                        {subcontractor.price2 > 0 && (
                                                                                            <Text fontSize="sm" fontWeight="bold" color={'#adadad'}>
                                                                                                - {subcontractor.price2} kr. pr. {translatePriceType(subcontractor.priceType2)}
                                                                                            </Text>
                                                                                        )}
                                                                                    </div>
                                                                                ) : (
                                                                                    <Text fontSize="sm" fontWeight="bold" color={textColor}>
                                                                                        {subcontractor.name}
                                                                                    </Text>
                                                                                )}
                                                                            </Card>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Card border={'2px solid'} borderColor={contractorBoxColor} key={subcontractor.id} width={'100%'} color="white" p="10px" borderRadius="5px" cursor="pointer" onClick={() => { setSubContractor(subcontractor.id); messageTab.current.click() }}>
                                                                                {subcontractor.isGlobal ? (
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
                                                                                        <Text fontSize="sm" fontWeight="bold" color={textColor}>
                                                                                            {subcontractor.name}
                                                                                        </Text>
                                                                                        <Text fontSize="sm" fontWeight="bold" color={'#adadad'}>
                                                                                            - {subcontractor.price} kr. pr. {translatePriceType(subcontractor.priceType)}
                                                                                        </Text>
                                                                                        {subcontractor.price2 > 0 && (
                                                                                            <Text fontSize="sm" fontWeight="bold" color={'#adadad'}>
                                                                                                - {subcontractor.price2} kr. pr. {translatePriceType(subcontractor.priceType2)}
                                                                                            </Text>
                                                                                        )}
                                                                                    </div>
                                                                                ) : (
                                                                                    <Text fontSize="sm" fontWeight="bold" color={textColor}>
                                                                                        {subcontractor.name}
                                                                                    </Text>
                                                                                )}
                                                                            </Card>
                                                                        )
                                                                    }
                                                                })}
                                                            </>)}

                                                    </Grid>
                                                    {selectedSubContractor !== null && (
                                                        <Button
                                                            variant="no-hover"
                                                            bg="#151928"
                                                            alignSelf="flex-end"
                                                            mt="24px"
                                                            w="100px"
                                                            h="35px"
                                                            onClick={() => { if (messageTab.current) { messageTab.current.click() } }}
                                                        >
                                                            <Text fontSize="xs" color="#fff" fontWeight="bold">
                                                                Næste
                                                            </Text>
                                                        </Button>
                                                    )}
                                                </Flex>
                                            </CardBody>
                                        </Card>
                                    </TabPanel>

                                    <TabPanel>

                                        {selectedSubContractor && process.env.REACT_APP_ESTATEPITCH_SUBCONTRACTOR_ID === selectedSubContractor.id ? (
                                            <Card>
                                                <CardHeader mb="10px">
                                                    <Text
                                                        color={textColor}
                                                        fontSize="lg"
                                                        fontWeight="bold"
                                                        mb="3px"
                                                    >
                                                        Send ordren
                                                    </Text>

                                                </CardHeader>
                                                <CardBody>
                                                    <Flex direction="column" w="100%">
                                                        <Flex align="center" mb="18px">
                                                            <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                                                                Er der noget der skal ligges særlig vægt på i teksten?
                                                            </Text>
                                                        </Flex>
                                                        <Flex align="center" mb="18px">
                                                            <Textarea placeholder="eks. 'Udsigten fra stuen ud over vandet' eller 'Total istandsat i 2024'" defaultValue={''} name="highlightText" onBlur={(e: any) => { handleHighlightTextChange(e.target.value) }} />
                                                        </Flex>

                                                        <Flex align="center" mb="18px">
                                                            <Text fontSize="12px" fontStyle={"italic"} color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                                                                Bemærk: teksten bliver automatisk genereret og kan indeholde unøjagtigheder.<br /><br />
                                                                For at kunne behandle ordren, skal følgende være opfyldt:<br />
                                                                - Der skal værer mindst 5 billeder, jo flere jo bedre<br />
                                                                - Der skal værer en plantegning<br />
                                                                <br />
                                                                Når alle betingelser er opfyldt, genereres teksten automatisk inden for 15 minutter.
                                                            </Text>
                                                        </Flex>


                                                        <Flex justify="space-between">
                                                            <Button
                                                                variant="no-hover"
                                                                bg={bgPrevButton}
                                                                alignSelf="flex-end"
                                                                mt="24px"
                                                                w="100px"
                                                                h="35px"
                                                                onClick={() => { if (subcontractorTab.current) { subcontractorTab.current.click() } }}

                                                            >
                                                                <Text fontSize="xs" color="gray.700" fontWeight="bold">
                                                                    Tilbage
                                                                </Text>
                                                            </Button>
                                                            <Button
                                                                variant="no-hover"
                                                                bg="#151928"
                                                                alignSelf="flex-end"
                                                                mt="24px"
                                                                w="100px"
                                                                h="35px"
                                                                onClick={() => { sentMaterial() }}
                                                            >
                                                                <Text fontSize="xs" color="#fff" fontWeight="bold">
                                                                    Opret ordre
                                                                </Text>
                                                            </Button>
                                                        </Flex>
                                                    </Flex>

                                                </CardBody>

                                            </Card>
                                        ) : (

                                            <Card>
                                                <CardHeader mb="40px">
                                                    <Text
                                                        color={textColor}
                                                        fontSize="lg"
                                                        fontWeight="bold"
                                                        mb="3px"
                                                    >
                                                        Besked
                                                    </Text>
                                                </CardHeader>
                                                <CardBody>


                                                    <Flex direction="column" w="100%">

                                                        <Flex align="center" mb="18px">
                                                            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                                                                Emne:{" "}
                                                            </Text>
                                                            <Input
                                                                variant="flushed"
                                                                type="text"
                                                                name="mailSubject"
                                                                defaultValue={(selectedSubContractor !== null ? selectedSubContractor.mailSubject : "")}
                                                                onBlur={(e) =>
                                                                    updateData("mailSubject", e.target.value)
                                                                }
                                                            />
                                                        </Flex>
                                                        <Flex align="center" mb="18px">
                                                            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                                                                Sendes til:{" "}
                                                            </Text>

                                                            {selectedSubContractor && (
                                                                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                                                                    {selectedSubContractor.bookingEmail}
                                                                </Text>
                                                            )}



                                                        </Flex>
                                                        <Flex align="center" mb="18px">
                                                            {dataRenderedTexts && (
                                                                <Editor value={(dataRenderedTexts !== null ? dataRenderedTexts.getSubcontractorRenderedMailText.mailText : "")} changed={(e) => (updateText(e))} showFields={false} />
                                                            )}
                                                        </Flex>


                                                        <Flex justify="space-between">
                                                            <Button
                                                                variant="no-hover"
                                                                bg={bgPrevButton}
                                                                alignSelf="flex-end"
                                                                mt="24px"
                                                                w="100px"
                                                                h="35px"
                                                                onClick={() => { if (subcontractorTab.current) { subcontractorTab.current.click() } }}

                                                            >
                                                                <Text fontSize="xs" color="gray.700" fontWeight="bold">
                                                                    Tilbage
                                                                </Text>
                                                            </Button>
                                                            <Button
                                                                variant="no-hover"
                                                                bg="#151928"
                                                                alignSelf="flex-end"
                                                                mt="24px"
                                                                w="100px"
                                                                h="35px"
                                                                onClick={() => { sentMaterial() }}
                                                            >
                                                                <Text fontSize="xs" color="#fff" fontWeight="bold">
                                                                    Opret ordre
                                                                </Text>
                                                            </Button>
                                                        </Flex>
                                                    </Flex>

                                                </CardBody>

                                            </Card>
                                        )}
                                    </TabPanel>

                                </TabPanels>
                            </Tabs>
                        </Flex>
                    </Flex>

                </ModalBody>

            </ModalContent >
        </Modal >


    );

}

export default TextOrderDialog;