// Chakra imports
import { Flex, Text, useColorModeValue, Input, useToast, Checkbox } from "@chakra-ui/react";
// Custom components
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import { gql, useMutation } from "@apollo/client";
//import { useAuth } from "./../../../../auth-context/auth.context";

const updateCustomerInfoGql = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
    }
  }
`;

const CustomerIntegrations = ({ data }) => {
  const toast = useToast();
 // const userData = useAuth()
  const [updateCustomerInfo] = useMutation(updateCustomerInfoGql);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const updateData = (field, value, elm?) => {
    const dataObj = {}

    dataObj['id'] = data.id;
    if (field === 'zipCodeAndCity') {
      dataObj['zipCode'] = value.match(/\d+/g)[0];
      dataObj['city'] = value.match(/[a-zA-Z]+/g)[0];
    } else {

      if (field === 'mwSystemName' && (value !== '')) {
        if (!value.includes('https://')) {
          value = 'https://' + value;
        }
        if (!value.includes('mindworking.eu')) {
          value = value + '.mindworking.eu';
        }

        elm.value = value;
      }

      dataObj[field] = value;
    }

    updateCustomerInfo({ variables: { input: dataObj } })
      .then(() => {
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, ændringen blev ikke gemt.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Integrationer
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="normal" minWidth={"110px"} me="10px" whiteSpace={"nowrap"}>
              Mindworking
            </Text>
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"110px"} me="10px" whiteSpace={"nowrap"}>
              Butiks Nr.:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="mwShopId"
              defaultValue={data.mwShopId}
              onBlur={(e) => updateData('mwShopId', e.currentTarget.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"110px"} me="10px" whiteSpace={"nowrap"}>
              System url:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              placeholder="https://<system navn>.mindworking.dk"
              name="mwSystemName"
              defaultValue={data.mwSystemName}
              onBlur={(e) => { updateData('mwSystemName', e.currentTarget.value, e.currentTarget); }}
            />
          </Flex>
            <>
              <Flex align="center" mb="18px">
              </Flex>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} fontWeight="normal" minWidth={"110px"} me="10px" whiteSpace={"nowrap"}>
                  Leadhub Estate
                </Text>
              </Flex>
              <Flex align="center" mb="18px">
                <Checkbox size='lg' iconColor='gray.800' colorScheme="white" defaultChecked={data.leadhubEnabled ? true : false} onChange={(e) => updateData('leadhubEnabled', e.target.checked, true)}>
                  <Text fontSize="16px" color={textColor} >
                    Aktiver integrationen
                  </Text>
                </Checkbox>
              </Flex>
            </>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default CustomerIntegrations;
