// Chakra imports
import { Text, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Flex, Grid, GridItem, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react";
import ProfileBgImage from "../../../../assets/img/ProfileBackground.png";
import SettingsHeader from "../components/SettingsHeader";
import { useHistory } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import SettleTable from "./../../../../components/Tables/SettleTable";
import React from "react";
import CardHeader from "../../../../components/Card/CardHeader";

const GET_TEXTPROFILES = gql`
query getTextProfiles {
  getTextProfiles {
    id
    name
    global
    textSections {
      id
  }
  }
}`

const DELETE_TEXTPROFILE = gql`
mutation deleteTextProfile($id: ID!){
    deleteTextProfile(id:$id)
}`

const CREATE_TEXTPROFILE = gql`
mutation createTextProfile($input: CreateTextProfileInput!){
  createTextProfile(input:$input){
    id
  }
}`

const UPDATE_TEXTPROFILE = gql`
mutation updateTextProfile($input: UpdateTextProfileInput!){
  updateTextProfile(input:$input){
    id
  }
}`

const CreateTextProfileObj = {
  name: '' as string,
}

function TextProfiles() {
  // Chakra color mode
  const toast = useToast()
  const history = useHistory();
  const deleteWarningCancelRef = React.useRef<HTMLButtonElement>(null);
  const [validate, setValidate] = React.useState(false);
  const [createTextProfileData, setCreateTextProfileData] = React.useState(CreateTextProfileObj);
  const { isOpen: deleteWarningIsOpen, onOpen: deleteWarningOnOpen, onClose: deleteWarningOnClose } = useDisclosure();
  const { isOpen: createTextProfileIsOpen, onOpen: createTextProfileOnOpen, onClose: createTextProfileOnClose } = useDisclosure();
  const [selectedTextProfileId, setSelectedTextProfileId] = React.useState('');
  const [isCreateMode, setIsCreateMode] = React.useState(true);
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const [deleteTextProfile] = useMutation(
    DELETE_TEXTPROFILE,
    {
      refetchQueries: [GET_TEXTPROFILES],
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )

  const [createNewTextProfile] = useMutation(
    CREATE_TEXTPROFILE,
    {
      refetchQueries: [GET_TEXTPROFILES],
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )

  const [updateTextProfile] = useMutation(
    UPDATE_TEXTPROFILE,
    {
      refetchQueries: [GET_TEXTPROFILES],
    }
  )

  const { loading, error, data } = useQuery(GET_TEXTPROFILES, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting text profile from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting text profile from server (2)</p>);
  }

  function rowClicked(obj) {
    history.push('/admin/setting/textprofile/' + obj.id);
  }

  const updateTextProfileClicked = () => {
    if (CreateTextProfileObj.name === '') {
      console.log('Validate - CreateTextProfileObj:', CreateTextProfileObj)
      setValidate(true)
    } else {
      const input = { ...CreateTextProfileObj, id: selectedTextProfileId }

      updateTextProfile({
        variables: { input: input } as any
      }).then(() => createTextProfileOnClose())
        .catch((() => {
          toast({
            title: 'Opdatering af tekst profilen fejlede.',
            description: "Det var ikke muligt at oprette tekst profilen.",
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        })
        );
    }
  }

  const createTextProfile = () => {
    if (CreateTextProfileObj.name === '') {
      console.log('Validate - CreateTextProfileObj:', CreateTextProfileObj)
      setValidate(true)
    } else {
      createNewTextProfile({
        variables: { input: CreateTextProfileObj } as any
      }).then(() => createTextProfileOnClose())
        .catch((() => {
          toast({
            title: 'Opret tekst profile fejlede.',
            description: "Det var ikke muligt at oprette tekst profilen.",
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        })
        );
    }
  }

  const createTextProfileClicked = () => {
    setIsCreateMode(true);
    CreateTextProfileObj.name = '';

    setCreateTextProfileData(CreateTextProfileObj)
    setValidate(false)
    createTextProfileOnOpen();
  }

  const updateData = (field, value) => {
    CreateTextProfileObj[field] = value;
    setCreateTextProfileData(CreateTextProfileObj);
  }

  const deleteTextProfileClicked = (obj) => {
    setSelectedTextProfileId(obj.id)
    deleteWarningOnOpen();
  }

  const deleteTextProfileConfirmed = () => {
    deleteTextProfile({ variables: { id: selectedTextProfileId } } as any);
    deleteWarningOnClose();
  }

  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true
    },
    {
      header: "Navn",
      accessor: "name",
      onClick: rowClicked,
      width: 200,
    },
    {
      header: "Tekst felter",
      accessor: "fieldCount",
      onClick: rowClicked,
      width: 200,
    },
    {
      header: "Rediger",
      icon: "edit",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      accessor: "delete",
      onClick: rowClicked,
    },
    {
      header: "Slet",
      accessor: "2",
      icon: "delete",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: deleteTextProfileClicked,
    },
  ];

  let tableData = JSON.parse(JSON.stringify(data.getTextProfiles));

  for (var i = 0; i < tableData.length; i++) {
    //tableData[i].showOnOrderFormTranslated = tableData[i].showOnOrderForm ? 'Ja' : 'Nej';
    tableData[i].fieldCount = tableData[i].textSections.length;
  }

  return (
    <Flex direction="column">
      <SettingsHeader
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={"Tekst profiler"}
        description={"Her kan du redigere og oprettet tekst profiler til dine kunder."}
        showCreateUserButton={false}
        onCreateUserClicked={() => { }}
        showBackButton={false}
        showUserSentWellcomesMessageButton={false}
        onSentWellcomesMessageClicked={() => { }}
      />

      <Grid templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap="24px">
        <GridItem colSpan={3} rowSpan={0} >
          <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
            <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">
              <Flex direction="column">
                <Text fontSize="lg" color={textColor} fontWeight="bold">
                  Tekst profiler
                </Text>
              </Flex>
              <Flex direction="column">
                <Text
                  color={textColor}
                  fontSize="12px"
                  fontWeight="bold"
                  mb="6px"
                  cursor="pointer"
                  onClick={() => {
                    createTextProfileClicked();
                  }}
                >
                  Opret ny tekst profil
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <SettleTable data={tableData} columnsData={columns} csvData={[]} setDragData={false} enableSelect={false} />
            </CardBody>
          </Card>
        </GridItem>
      </Grid>

      <Modal onClose={createTextProfileOnClose} size="xl" isOpen={createTextProfileIsOpen}>
        <ModalOverlay />
        <ModalContent>
          {isCreateMode ? <ModalHeader>Opret tekst profile</ModalHeader> : <ModalHeader>Rediger tekst profil</ModalHeader>}

          <ModalCloseButton />
          <ModalBody>
            {isCreateMode ? <Text>Du er ved at oprette en ny tekst profil, udfyld nedenstående<br /> og klik opret.</Text> : <Text>Du er ved at redigere en tekst profil, ret nedenstående<br /> og klik opdater.</Text>}
            <br /><br />
            <Text fontSize='md' fontWeight='bold'>Tekst profil:</Text>

            <Flex direction='column' style={{ width: '100%' }}>
              <Flex align='center' mb='18px'>
                <Text fontSize='md' color={textColor} fontWeight="bold" me='10px' whiteSpace={'nowrap'} minWidth={'110px'}>
                  Navn:{" "}
                </Text>
                <Input
                  variant='flushed'
                  type='text'
                  name="name"
                  defaultValue={createTextProfileData.name}
                  onKeyUp={(e) => { updateData('name', e.currentTarget.value) }}
                  className={validate && createTextProfileData.name === '' ? 'error' : ''}
                />
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={createTextProfileOnClose}>Fortryd</Button>
            {isCreateMode ? <Button colorScheme='green' onClick={createTextProfile} ml={3}>Opret</Button> : <Button colorScheme='green' onClick={updateTextProfileClicked} ml={3}>Opdater</Button>}

          </ModalFooter>
        </ModalContent>
      </Modal>


      <AlertDialog isOpen={deleteWarningIsOpen} leastDestructiveRef={deleteWarningCancelRef} onClose={deleteWarningOnClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet tekst profil
            </AlertDialogHeader>

            <AlertDialogBody>Du er ved at slette en tekst profil og alle underlæggende felter.<br />Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={deleteWarningOnClose}>
                Nej, behold
              </Button>
              <Button colorScheme="red" onClick={deleteTextProfileConfirmed} ml={3}>
                Ja, jeg er sikker
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default TextProfiles;
