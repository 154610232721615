// Chakra imports
import { Flex, Text, useColorModeValue, useToast, Input,Select } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import { gql, useMutation } from "@apollo/client";

const UPDATE_ORDER_GQL = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
    }
  }
`;

const OrderSeller = ({ data, dataChanged }) => {
  ///const toast = useToast();
  // Chakra color mode
  const [updateOrderInfo] = useMutation(UPDATE_ORDER_GQL);
  const textColor = useColorModeValue("gray.700", "white");
  const toast = useToast();

  const updateData = (field, fieldData) => {
    let inputData = {};
    inputData.id = data.id;
    inputData[field] = fieldData;

    updateOrderInfo({
      variables: { input: inputData },
    })
      .then(() => {
        dataChanged();
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Opdatering af ordren fejlede.",
          description: "Det var ikke muligt at opdatere ordren.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });

  };

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} style={{ height: "100%" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          kontaktperson
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              navn:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="name"
              defaultValue={data.sellerName}
              onBlur={(e) => updateData('sellerName', e.currentTarget.value)}
            />

          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              Telefon:{" "}
            </Text>

            <Input
              variant="flushed"
              type="text"
              name="name"
              defaultValue={data.sellerPhone}
              onBlur={(e) => updateData('sellerPhone', e.currentTarget.value)}
            />
          </Flex>
          <Flex align="flex-start" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              E-mail:{" "}
            </Text>

            <Input
              variant="flushed"
              type="text"
              name="name"
              defaultValue={data.sellerEmail}
              onBlur={(e) => updateData('sellerEmail', e.currentTarget.value)}
            />
          </Flex>

          <Flex align="flex-start" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              Nøglesag:{" "}
            </Text>
            <Select multiple={false} value={data.keyCase} onChange={(e) => {
              updateData("keyCase", e.target.value);
              
              }
            }>
              <option key={'NO'} value={'NO'}>Nej</option>
              <option key={'COORDINATE_WITH_ESTATEAGENT'} value={'COORDINATE_WITH_ESTATEAGENT'}>Koordiner med ejendomsmælger</option>
              <option key={'COORDINATE_WITH_CONTACTPERSONO'} value={'COORDINATE_WITH_CONTACTPERSON'}>Koordiner med kontaktperson</option>
            </Select>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default OrderSeller;
