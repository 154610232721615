import {
    Flex, Text, useColorModeValue, useToast,
    Select,
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Button
} from "@chakra-ui/react";
import { useState, useRef, useEffect } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";

const UPDATE_ORDER_GQL = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
    }
  }
`;

const GET_PRODUCTS = gql`
  query getCustomer($id: ID!) {
    getCustomer(id: $id) {
        id
        productCategories {
            id
            name
            products {
                id
                name
                description
                subProducts {
                    id
                    name
                    description
                }
            }
        }
    }   
}`



interface IProps {
    onClose: any,
    customerId: string,
    orderId: string,
    isOpen: boolean,
    products: any
}

const OrderChangeProductsDialog: React.FC<IProps> = ({ orderId, customerId, isOpen, onClose, products }) => {
    const [selectedMainProduct, setSelectedMainProduct] = useState({} as any);
    const textColor = useColorModeValue("gray.700", "white");
    const toast = useToast();
    const subProduct1 = useRef(null);
    const subProduct2 = useRef(null);
    const subProduct3 = useRef(null);
    const mainProduct = useRef(null);

    const [updateOrderInfo] = useMutation(UPDATE_ORDER_GQL);
    const { loading: loadingProducts, error: errorProducts, data: dataProducts } = useQuery(GET_PRODUCTS, {
        variables: { id: customerId },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });


    useEffect(() => {
        if (dataProducts) {
            let mainProduct = dataProducts.getCustomer.productCategories.map((category: any) => {
                return category.products.filter((product: any) => {
                    //is in products
                    return products.find((p: any) => p.id === product.id) !== undefined;

                })
            }).flat(1)[0];
            setSelectedMainProduct(mainProduct);
        }
    }, [dataProducts, products])


    if (loadingProducts) return <p></p>; //loading

    if (errorProducts) {
        return <p>Error getting products from server (1)</p>;
    }

    if (!dataProducts) {
        return <p>Error getting products from server (2)</p>;
    }


    const handleSaveChanges = () => {

        const mainProductId = mainProduct.current.value;
        let subProductId1 = "";
        if (subProduct1.current) {
            subProductId1 = subProduct1.current.value;
        }

        let subProductId2 = "";
        if (subProduct2.current) {
            subProductId2 = subProduct2.current.value;
        }

        let subProductId3 = "";
        if (subProduct3.current) {
            subProductId3 = subProduct3.current.value;
        }

        let inputData = {} as any;
        inputData.id = orderId;
        inputData.products = [];
        inputData.products.push(mainProductId);
        if (subProductId1 !== "") {
            inputData.products.push(subProductId1);
        }
        if (subProductId2 !== "") {
            inputData.products.push(subProductId2);
        }
        if (subProductId3 !== "") {
            inputData.products.push(subProductId3);
        }

        updateOrderInfo({
            variables: { input: inputData },
        })
            .then(() => {
                onClose(true);
                toast({
                    title: "Ændringen er blevet gemt.",
                    description: "",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                });
            })
            .catch((e) => {
                console.error(e)
                toast({
                    title: "Opdatering af ordren fejlede.",
                    description: "Det var ikke muligt at opdatere ordren.",
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
            });


    }

    const handleMainProductChange = (productId: string) => {
        console.log('productId', productId)
        //find adon products
        let mainProduct = dataProducts.getCustomer.productCategories.map((category: any) => {
            return category.products.filter((product: any) => {
                return product.id === productId;
            })
        }).flat(1)[0];
        setSelectedMainProduct(mainProduct);
    }

    console.log('products', products)

    //find all selected subproducts
    let selectedSubProducts = products.filter((p: any) => p.id !== selectedMainProduct.id);
    //let selectedSubProducts = products.find((p: any) => p.id !== selectedMainProduct.id);
    console.log('selectedSubProducts', selectedSubProducts)
    return (
        <Modal onClose={() => { onClose(false) }} size="xl" isOpen={isOpen} >
            <ModalOverlay />
            <ModalContent maxW="800px" bg={'#f9f9f9'}>
                <ModalHeader>Vælg produkt</ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    <Flex direction='column' style={{ width: '100%' }}>
                        <Flex align='center' mb='18px'>
                            <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                Produkt:{" "}
                            </Text>
                            <Select ref={mainProduct} multiple={false} onChange={(e) => { handleMainProductChange(e.target.value) }}>
                                {dataProducts.getCustomer.productCategories.map((category: any) => {
                                    return (
                                        <optgroup key={category.id} label={category.name}>
                                            {category.products.map((product: any) => {

                                                if (products.find((p: any) => p.id === product.id) === undefined) {
                                                    return (
                                                        <option key={product.id} value={product.id}>{product.name}</option>
                                                    )
                                                }
                                                return (
                                                    <option key={product.id} value={product.id} selected>{product.name}</option>
                                                )
                                            })}
                                        </optgroup>
                                    )

                                })}
                            </Select>
                        </Flex>
                        {selectedMainProduct && selectedMainProduct.subProducts && selectedMainProduct.subProducts.length > 0 && (
                            <>
                                <Flex align='center' mb='18px'>
                                    <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                        Tillægsprodukt 1:{" "}
                                    </Text>
                                    <Select multiple={false} ref={subProduct1} >
                                        <option key={'none'} value={''} selected>Ingen</option>
                                        {selectedMainProduct.subProducts.map((subProduct: any) => {
                                            if (selectedSubProducts.length > 0 && subProduct.id === selectedSubProducts[0].id) {
                                                return (
                                                    <option key={subProduct.id} value={subProduct.id} selected>{subProduct.name}</option>
                                                )
                                            }else{
                                                return (
                                                    <option key={subProduct.id} value={subProduct.id}>{subProduct.name}</option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Flex>
                                <Flex align='center' mb='18px'>
                                    <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                        Tillægsprodukt 2:{" "}
                                    </Text>
                                    <Select multiple={false} ref={subProduct2} >
                                        <option key={'none'} value={''} selected>Ingen</option>
                                        {selectedMainProduct.subProducts.map((subProduct: any) => {
                                            if (selectedSubProducts.length > 1 && subProduct.id === selectedSubProducts[1].id) {
                                                return (
                                                    <option key={subProduct.id} value={subProduct.id} selected>{subProduct.name}</option>
                                                )
                                            }else{
                                                return (
                                                    <option key={subProduct.id} value={subProduct.id}>{subProduct.name}</option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Flex>
                                <Flex align='center' mb='18px'>
                                    <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                        Tillægsprodukt 3:{" "}
                                    </Text>
                                    <Select multiple={false} ref={subProduct3} >
                                        <option key={'none'} value={''} selected>Ingen</option>
                                        {selectedMainProduct.subProducts.map((subProduct: any) => {
                                            if (selectedSubProducts.length > 2 && subProduct.id === selectedSubProducts[2].id) {
                                                return (
                                                    <option key={subProduct.id} value={subProduct.id} selected>{subProduct.name}</option>
                                                )
                                            }else{
                                                return (
                                                    <option key={subProduct.id} value={subProduct.id}>{subProduct.name}</option>
                                                )
                                            }
                                        })}
                                    </Select>
                                </Flex>
                            </>
                        )}
                    </Flex>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => { onClose(false) }}>Fortryd</Button>
                    <Button colorScheme='green' onClick={() => { handleSaveChanges() }} ml={3}>Ok</Button>
                </ModalFooter>
            </ModalContent >
        </Modal >
    );
}

export default OrderChangeProductsDialog;