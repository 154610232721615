/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra Imports

// Custom Icons
import { SettingsIcon } from "../Icons/Icons";
import { FaSistrix  } from "react-icons/fa";
// Custom Components
import { SidebarResponsive } from "../Sidebar/Sidebar";
import PropTypes from "prop-types";
import React from "react";
import routes from "./../../routes.js";
import { useHistory } from "react-router-dom";
import {
  Flex,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure
} from "@chakra-ui/react";
import GlobalSearch from "../../views/Dashboard/Dashboard/components/GlobalSearch";

export default function HeaderLinks(props) {
  const { secondary, ...rest } = props;
  const history = useHistory();
  const { isOpen: globalSearchIsOpen, onOpen: globalSearchOnOpen, onClose: globalSearchOnClose } = useDisclosure();
  //const textColor = useColorModeValue("gray.700", "white");
  // Chakra Color Mode
  //let mainTeal = useColorModeValue("teal.300", "teal.300");
  //let inputBg = useColorModeValue("white", "gray.800");
  //let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  //let searchIcon = useColorModeValue("gray.700", "gray.200");

  if (secondary) {
    navbarIcon = "white";
  }
  const settingsRef = React.useRef();


  const handleGlobalSeachClick = () => {
    globalSearchOnOpen();
  }

  const handleGlobalSearchItemClicked = (item) => {
    globalSearchOnClose();
    history.push(item.addressLink.replace('/#',''));
  }

  return (
    <>
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
    >
      
      
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        // logo={logo}
        {...rest}
      />
      <FaSistrix 
        cursor="pointer"
        me="16px"
        ref={settingsRef}
        onClick={handleGlobalSeachClick}
        color={navbarIcon}
        w="18px"
        h="18px"
        
      />
      <div style={{width: '20px'}}></div>
      <SettingsIcon
        cursor="pointer"
        me="16px"
        ref={settingsRef}
        onClick={props.onOpen}
        color={navbarIcon}
        w="18px"
        h="18px"
      />
      
    </Flex>
    {/* Modal for download calender */}
    <Modal onClose={globalSearchOnClose} size="xl" isOpen={globalSearchIsOpen}>
    <ModalOverlay />
    <ModalContent width={470}>
      <ModalHeader>Søg</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Flex direction="column" justifyContent="Center" style={{ width: "100%", height: "100%" }}>
          <GlobalSearch orderClicked={(item)=>{handleGlobalSearchItemClicked(item)}} />
        </Flex>
      </ModalBody>
      <ModalFooter>

      </ModalFooter>
    </ModalContent>
  </Modal>
  </>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
