// Chakra imports
import {
    Flex, useColorModeValue, Text, Grid, GridItem,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    Select,
    useToast,
    Stack,
    Skeleton
} from "@chakra-ui/react";
import ProfileBgImage from "./../../../assets/img/ProfileBackground.png";
import { useState } from "react";
import Card from "./../../../components/Card/Card";
import CardBody from "./../../../components/Card/CardBody";
import OrderHeader from "./components/orderHeader"
import SettleTable from "./../../../components/Tables/SettleTable";
import OrdersDataSelector from "./components/ordersDataSelector"
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useAuth } from "../../../auth-context/auth.context";
import OrderChangeAssignedUserDialog from "./components/orderChangeAssignedUserDialog";

const UPDATE_ORDERS_GQL = gql`
mutation updateOrders($input: UpdateOrdersInput!){
    updateOrders(input: $input)
}`

const ORDERS_GQL = gql`
query getFilteredOrders($input: GetFilteredOrdersInput!){
    getProducts {
        id
        name
    }
    getFilteredOrders(input: $input){
        id
        caseNo
        createdAt
        products
        estateAddress
        estateZipCode
        estateCity
        customerName
        photeshotEvent
        assignedUserName
        status
        authorOrderCreatedAt
        authorName
        deliveryDate
        keyCase
    }
    users {
      id
      name
      userType
    }
    getCustomersByUser{
        id
        name
        productCategories {
            id
            name
            products {
                id
                name
            }
        }
    }
}`

function Orders() {

    const { isOpen: changeStatusIsOpen, onOpen: changeStatusOnOpen, onClose: changeStatusOnClose } = useDisclosure();
    const history = useHistory();
    const toast = useToast();
    const userData = useAuth()
    // Chakra color mode
    let today = new Date();
    today.setDate(today.getDate() + 1)
    let defaultfromDate = new Date();
    defaultfromDate.setMonth(today.getMonth() - 1)

    const { paramsCustomerId } = useParams();
    const [showAssignedUserChangeDialog, setShowAssignedUserChangeDialog] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectedView, setselectedView] = useState('NORMAL');
    const [changeToStatus, setChangeToStatus] = useState('RECEIVED');

    let customerId = '';
    if (localStorage.getItem("order_selected_customerId")) {
        customerId = localStorage.getItem("order_selected_customerId");
    }

    let productId = '';
    if (localStorage.getItem("order_selected_productId")) {
        productId = localStorage.getItem("order_selected_productId");
    }

    let userId = '';
    if (localStorage.getItem("order_selected_userId")) {
        userId = localStorage.getItem("order_selected_userId");
    }

    let status = 'ALL';
    if (localStorage.getItem("order_selected_status")) {
        status = localStorage.getItem("order_selected_status");
    }

    let fromIsoDate = defaultfromDate.toISOString().split('T')[0];
    if (localStorage.getItem("order_selected_fromDate")) {
        fromIsoDate = localStorage.getItem("order_selected_fromDate");
    }

    let toIsoDate = today.toISOString().split('T')[0];
    if (localStorage.getItem("order_selected_toDate")) {
        toIsoDate = localStorage.getItem("order_selected_toDate");
    }

    localStorage.setItem("order_selected_fromDate", fromIsoDate);
    localStorage.setItem("order_selected_toDate", toIsoDate);

    const [requestData, setrequestData] = useState({
        fromDate: fromIsoDate,
        toDate: toIsoDate,
        status: status,
        customerId: customerId,
        productId: productId,
        userId: userId
    });

    //Colors
    const textColor = useColorModeValue("gray.700", "white");
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );


    const [updateOrders] = useMutation(
        UPDATE_ORDERS_GQL,
        {
            refetchQueries: [ORDERS_GQL],
            variables: {
                input: {
                    fromDate: requestData.fromDate,
                    toDate: requestData.toDate,
                    status: requestData.status,
                    customerId: paramsCustomerId ? paramsCustomerId : requestData.customerId,
                    productId: requestData.productId,
                    assignedUserId: requestData.userId
                }
            }
        }
    )



    const { loading, error, data, refetch } = useQuery(ORDERS_GQL, {
        variables: {
            input: {
                fromDate: requestData.fromDate,
                toDate: requestData.toDate,
                status: requestData.status,
                customerId: paramsCustomerId ? paramsCustomerId : requestData.customerId,
                productId: requestData.productId,
                assignedUserId: requestData.userId
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        manual: true,
    });

    if (loading) return (
        <Flex direction="column">
            <OrderHeader
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                name={"Ordre"}
                description={'Udsøgning af ordre i systemet'}
            />

            <Grid templateColumns={{ sm: "1fr", xl: "1fr" }} gap="22px">
                <GridItem w='100%' >
                    <Card p="16px" >
                        <Stack>
                            <Skeleton height='40px' style={{ borderRadius: '10px;', marginBottom: '12px' }} />
                            <Skeleton height='40px' style={{ borderRadius: '10px;', marginBottom: '12px' }} />
                            <Skeleton height='40px' style={{ borderRadius: '10px;', marginBottom: '12px' }} />
                            <Skeleton height='40px' style={{ borderRadius: '10px;', marginBottom: '12px' }} />
                            <Skeleton height='40px' style={{ borderRadius: '10px;', marginBottom: '12px' }} />
                        </Stack>
                    </Card>
                </GridItem>


            </Grid>


        </Flex>
    ); //loading

    if (error) {
        return (<p>Error getting orders from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting orders from server (2)</p>);
    }

    const updatefn = (fromDate, toDate, selectedCustomer, status, selectedProductId, selectedUserId) => {

        console.log('updatefn - selectedUserId:', selectedUserId)
        let statusAry = [];
        statusAry.push(status);

        setrequestData({
            fromDate: fromDate,
            toDate: toDate,
            status: status,
            customerId: selectedCustomer,
            productId: selectedProductId,
            userId: selectedUserId
        })
    }

    let columns = [];

    if (selectedView === 'NORMAL') {
        columns = [
            {
                header: "",
                accessor: "id",
                hidden: true
            },
            {
                header: "Oprettet",
                accessor: "createdAt",
                onClick: rowClicked,
                disableFilters: true,
                sortType: (a, b) => {
                    if (a.values.createdAt === '') {
                        return 1;
                    }
                    if (b.values.createdAt === '') {
                        return -1;
                    }
                    const format = 'DD-MM-YYYY';
                    const dateA = moment(a.values.createdAt, format);
                    const dateB = moment(b.values.createdAt, format);
                    return dateB.toDate() - dateA.toDate();
                }
            },
            {
                header: "Produkt",
                accessor: "productName",
                onClick: rowClicked
            },
            {
                header: "Adresse",
                accessor: "fullAddress",
                onClick: rowClicked
            },
            {
                header: "Kunde navn",
                accessor: "customerName",
                onClick: rowClicked
            },
            {
                header: "Planlagt fotografering",
                accessor: "photeshotEventDate",
                onClick: rowClicked,
                disableFilters: true,
                sortType: (a, b) => {
                    if (a.values.photeshotEventDate === '') {
                        return 1;
                    }
                    if (b.values.photeshotEventDate === '') {
                        return -1;
                    }
                    const format = 'DD-MM-YYYY';
                    const dateA = moment(a.values.photeshotEventDate, format);
                    const dateB = moment(b.values.photeshotEventDate, format);
                    return dateB.toDate() - dateA.toDate();
                }
            },
            {
                header: "Tildelt",
                accessor: "assignedUserName",
                onClick: rowClicked
            },
            {
                header: "Status",
                accessor: "statusText",
                onClick: rowClicked
            }
        ];
    }

    if (selectedView === 'PLANNING_TEXT') {
        columns = [
            {
                header: "",
                accessor: "id",
                hidden: true
            },
            {
                header: "Oprettet",
                accessor: "createdAt",
                onClick: rowClicked,
                disableFilters: true,
                sortType: (a, b) => {
                    if (a.values.createdAt === '') {
                        return 1;
                    }
                    if (b.values.createdAt === '') {
                        return -1;
                    }
                    const format = 'DD-MM-YYYY';
                    const dateA = moment(a.values.createdAt, format);
                    const dateB = moment(b.values.createdAt, format);
                    return dateB.toDate() - dateA.toDate();
                }
            },
            {
                header: "Produkt",
                accessor: "productName",
                onClick: rowClicked
            },
            {
                header: "Adresse",
                accessor: "fullAddress",
                onClick: rowClicked
            },
            {
                header: "Planlagt Tekst",
                accessor: "assignedAutherDate",
                onClick: rowClicked,
                disableFilters: true,
                sortType: (a, b) => {
                    if (a.values.assignedAutherDate === '') {
                        return 1;
                    }
                    if (b.values.assignedAutherDate === '') {
                        return -1;
                    }
                    const format = 'DD-MM-YYYY';
                    const dateA = moment(a.values.assignedAutherDate, format);
                    const dateB = moment(b.values.assignedAutherDate, format);
                    return dateB.toDate() - dateA.toDate();
                }
            },
            {
                header: "Tildelt Tekst",
                accessor: "assignedAutherName",
                onClick: rowClicked
            },
            {
                header: "Status",
                accessor: "statusText",
                onClick: rowClicked
            }
        ];
    }


    function rowClicked(obj) {
        history.push('/admin/order/' + obj.id + '/planning');
    }

    const updateChangeStatus = (status) => {
        setChangeToStatus(status);
    }

    const handleChangeStatusClicked = () => {
        let inputData = {}
        inputData.orderIds = selectedOrders.map((order) => order.id);
        inputData.status = changeToStatus;

        updateOrders({
            variables: { input: inputData }
        }).then(() => {
            changeStatusOnClose();
            refetch()
        })
            .catch((error => {
                console.log('error:', error)
                toast({
                    title: 'Opdatering af status fejlede.',
                    description: "Det var ikke muligt at ændre status.",
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                });

            })
            );
    }


    const handleSelectionChanged = (selected) => {
        setSelectedOrders(selected)
    }

    //create obj for table component
    let tableData = JSON.parse(JSON.stringify(data.getFilteredOrders));

    for (var i = 0; i < tableData.length; i++) {

        tableData[i].createdAt = moment(tableData[i].createdAt).format('DD-MM-YYYY');
        tableData[i].productName = tableData[i].products.replace(/,/g, '\n');
        //tableData[i].customerName = tableData[i].customerName;
        if (tableData[i].keyCase !== "NO") {
            tableData[i].fullAddress = tableData[i].estateAddress + ', ' + tableData[i].estateZipCode + ' ' + tableData[i].estateCity + ' 🔑\nSags nr.: ' + tableData[i].caseNo;
        } else {
            tableData[i].fullAddress = tableData[i].estateAddress + ', ' + tableData[i].estateZipCode + ' ' + tableData[i].estateCity + '\nSags nr.: ' + tableData[i].caseNo;
        }

        tableData[i].photeshotEventDate = '';
        if (tableData[i].photeshotEvent) {
            tableData[i].photeshotEventDate = moment(tableData[i].photeshotEvent).format('DD-MM-YYYY');
        }

        //console.log('tableData[i].assignedUserName:', tableData[i].assignedUserName)
        //tableData[i].assignedUserName = tableData[i].assignedUserName;
        //tableData[i].assignedUserName = '';
        //if (tableData[i].assignedUserName) {
        //    tableData[i].assignedUserName = tableData[i].assignedUserName
        //}

        if (tableData[i].authorName === null) {
            tableData[i].assignedAutherName = '';
            tableData[i].assignedAutherDate = '';
        } else {
            tableData[i].assignedAutherName = tableData[i].authorName;
            tableData[i].assignedAutherDate = moment(tableData[i].authorOrderCreatedAt).format('DD-MM-YYYY');
        }


        switch (tableData[i].status) {
            case 'RECEIVED':
                tableData[i].statusText = 'Modtaget'
                break;
            case 'SELLER_NOT_RESPONDING':
                tableData[i].statusText = 'Kontaktperson træffes ikke'
                break;
            case 'AWAITING_RESPONSE_FROM_CONTACT_PERSON':
                tableData[i].statusText = 'Afventer svar fra kontaktperson'
                break;
            case 'PUT_ON_HOLD':
                tableData[i].statusText = 'Ordren er sat i bero'
                break;
            case 'FOTOSHOT_PLANED':
                tableData[i].statusText = 'Fotografering planlagt'
                break;
            case 'BEING_PROCESSED':
                tableData[i].statusText = 'Ordren efterbehandles'
                break;
            case 'PROCESSING_COMPLETED':
                tableData[i].statusText = 'Efterbehandling er afsluttet'
                break;
            case 'DELIVERED':
                if (tableData[i].deliveryDate === null) {
                    tableData[i].statusText = 'Ordren er leveret';
                } else {
                    tableData[i].statusText = 'Ordren er leveret' + '\nd. ' + moment(tableData[i].deliveryDate).format('DD-MM-YYYY');
                }
                break;
            case 'INVOICED':
                tableData[i].statusText = 'Ordren er faktureret'
                break;
            case 'CANCELED':
                tableData[i].statusText = 'Ordren er annulleret'
                break;
            case 'SEASON_FOTOSHOT_PLANED':
                tableData[i].statusText = 'Seasonopdatering planlagt'
                break;
            case 'WEATHER_UPDATE_FOTOSHOT_PLANED':
                tableData[i].statusText = 'Vejropdatering planlagt'
                break;
            default:
                break;
        }
    }

    var csvData = tableData.map(item => (
        {
            Oprettet: item.createdAt,
            Product: item.productName,
            KundeNavn: item.customerName,
            Adresse: item.fullAddress,
            Status: item.statusText,
            Tildelt: item.assignedUserName,
            Planlagt_fotografering: item.photeshotEventDate
        }
    ));

    let customButtonText1 = 'Ændre status';
    let customButtonText2 = null;
    if (selectedOrders.length === 0) {
        customButtonText1 = '';

    } else {
        if (userData.user.userType === 'ADMIN' || userData.user.userType === 'PHOTOGRAPHY_COMPANY_ADMIN') {
            customButtonText2 = 'Tildel';
        }
    }

    const filterUsers = () => {
        if (userData.user.userType === 'PHOTOGRAPHER') {
            return data.users.filter((user) => user.id === userData.user.id);
        }

        if (userData.user.userType === 'PHOTOGRAPHY_COMPANY_ADMIN') {

            return data.users.filter((user) => user.userType !== 'ADMIN');
        }

        if (userData.user.userType === 'ADMIN') {
            return data.users;
        }
    }

    return (
        <div>
            <Flex direction="column">
                <OrderHeader
                    backgroundHeader={ProfileBgImage}
                    backgroundProfile={bgProfile}
                    name={paramsCustomerId ? "Ordre - " + data.getCustomersByUser[0].name : "Ordrer"}
                    description={'Udsøgning af ordrer i systemet'}
                    onCreateOrderClicked={() => { }}
                    showCreateOrderButton={false}
                    showBackButton={false}
                    showTextButton={false}
                    onTextButtonClicked={() => { }}
                />
                <Grid templateColumns={{ sm: "1fr", xl: "1fr" }} gap="22px">
                    <GridItem w='100%'  >
                        <OrdersDataSelector
                            updatefn={updatefn}
                            fromDate={requestData.fromDate}
                            toDate={requestData.toDate}
                            status={requestData.status}
                            products={data.getProducts}
                            productId={requestData.productId}
                            showCustomerSelector={paramsCustomerId ? false : true}
                            customerId={requestData.customerId}
                            customers={data.getCustomersByUser}
                            users={filterUsers()}
                            userId={requestData.userId}
                        />
                    </GridItem>
                </Grid>
                <br />
                <Card px="0px">
                    <CardBody>
                        <SettleTable
                            csvData={csvData}
                            data={tableData}
                            columnsData={columns}
                            enableSelect={true}
                            onSelectedChanged={handleSelectionChanged}
                            customButtonText1={customButtonText1}
                            customButtonAction1={changeStatusOnOpen}
                            customButtonText2={customButtonText2}
                            customButtonAction2={() => { setShowAssignedUserChangeDialog(true) }}
                            customObject={(
                                <Select
                                    multiple={false}
                                    value={selectedView}
                                    onChange={(e) => {
                                        setselectedView(e.target.value)
                                    }}
                                >
                                    <option key={"NORMAL"} value="NORMAL">Normal</option>
                                    <option key={"PLANNING_TEXT"} value="PLANNING_TEXT">Planlægning - tekster</option>
                                </Select>
                            )}
                        />
                    </CardBody>
                </Card>
            </Flex>

            <OrderChangeAssignedUserDialog key={'OrderChangeAssignedUserDialog'} orderIds={selectedOrders.map((order) => order.id)} assignedUserId={''} isOpen={showAssignedUserChangeDialog} onClose={(updateData) => { setShowAssignedUserChangeDialog(false); if (updateData) { refetch(); } }} />


            <Modal onClose={changeStatusOnClose} size="xl" isOpen={changeStatusIsOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Ændre status</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Du er ved at ændre status på en eller flere ordre, udfyld nedenstående og klik ændre.<br />* Der udsendes ikke beskeder.</Text>
                        <br /><br />
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Product:{" "}
                                </Text>
                                <Select
                                    multiple={false}
                                    value={data.status}
                                    onChange={(e) => {
                                        updateChangeStatus(
                                            e.target.value
                                        );
                                    }}
                                >
                                    <option key={"RECEIVED"} value="RECEIVED">
                                        Modtaget
                                    </option>
                                    <option key={"SELLER_NOT_RESPONDING"} value="SELLER_NOT_RESPONDING">
                                        Kontaktperson træffes ikke
                                    </option>
                                    <option key={"AWAITING_RESPONSE_FROM_CONTACT_PERSON"} value="AWAITING_RESPONSE_FROM_CONTACT_PERSON">
                                        Afventer svar fra kontaktperson
                                    </option>
                                    <option key={"PUT_ON_HOLD"} value="PUT_ON_HOLD">
                                        Ordren er sat i bero
                                    </option>
                                    <option key={"FOTOSHOT_PLANED"} value="FOTOSHOT_PLANED">
                                        Fotografering planlagt
                                    </option>
                                    <option key={"SEASON_FOTOSHOT_PLANED"} value="SEASON_FOTOSHOT_PLANED">
                                        Seasonopdatering planlagt
                                    </option>
                                    <option key={"WEATHER_UPDATE_FOTOSHOT_PLANED"} value="WEATHER_UPDATE_FOTOSHOT_PLANED">
                                        Vejropdatering planlagt
                                    </option>
                                    <option key={"BEING_PROCESSED"} value="BEING_PROCESSED">
                                        Ordren efterbehandles
                                    </option>
                                    <option key={"PROCESSING_COMPLETED"} value="PROCESSING_COMPLETED">
                                        Efterbehandling er afsluttet
                                    </option>
                                    <option key={"DELIVERED"} value="DELIVERED">
                                        Ordren er leveret
                                    </option>
                                    <option key={"INVOICED"} value="INVOICED">
                                        Ordren er faktureret
                                    </option>
                                    <option key={"CANCELED"} value="CANCELED">
                                        Ordren er annulleret
                                    </option>
                                </Select>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={changeStatusOnClose}>Fortryd</Button>
                        <Button colorScheme='green' onClick={handleChangeStatusClicked} ml={3}>Ændre</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}

export default Orders;
