// Chakra imports
import {
  Flex, Text, useColorModeValue, useToast, ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
// Custom components
import Card from "../../../../components/Card/Card";
import { Select as ChakraSelect } from 'chakra-react-select';
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import { gql, useMutation, useApolloClient } from "@apollo/client";
import Editor from "../../../../components/Editor/Editor";
import { useState } from "react";
import './sentMessageDialog.css';

const sentMessageGql = gql`
  mutation sentMessage($input: SentMessagesInput!) {
    sentMessage(input: $input)
  }
`;

const SEARCH_ORDERS_GQL = gql`
  query searchOrders($search: String!) {
    searchOrders(search: $search){
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      status
      keyCase
    }
  }
`;

/*
const getMessagesGql = gql`
  query message($input: MessageInput!) {
    message(input: $input) {
      mailText
      mailSubject
      sentMailToCustomer
      sentMailToContactPerson
      sentMailToPhotograhyCompany
      sentMailToPhotograher
      sentSmsToCustomer
      sentSmsToContactPerson
      sentSmsToPhotograhyCompany
      sentSmsToPhotograher
    }
  }
`;
*/

export enum MessageType {
  ORDER_RECEIVED = "ORDER_RECEIVED", // eslint-disable-line
  ORDER_CONFIRMATION = "ORDER_CONFIRMATION", // eslint-disable-line
  UNSUCCESSFULL_CONTACT_ATTEMPT = "UNSUCCESSFULL_CONTACT_ATTEMPT", // eslint-disable-line
  ORDER_BOOKED = "ORDER_BOOKED", // eslint-disable-line
  ORDER_CANCELED = "ORDER_CANCELED", // eslint-disable-line
  ORDER_REMINDER = "ORDER_REMINDER", // eslint-disable-line
  ORDER_DELIVERED = "ORDER_DELIVERED", // eslint-disable-line
  ORDER_WEATHER_UPDATE_BOOKED = "ORDER_WEATHER_UPDATE_BOOKED", // eslint-disable-line
  ORDER_SEASON_UPDATE_BOOKED = "ORDER_SEASON_UPDATE_BOOKED" // eslint-disable-line
}

interface IProps {
  isVisible: boolean
  onClose: any
}




const SentMessageDialog: React.FC<IProps> = ({ onClose, isVisible }) => {
  const textColor = useColorModeValue("gray.700", "white");
  //const toast = useToast();
  const client = useApolloClient();
  //const [messageData, setMessageData] = useState(null);
  const [isFilterOrdersLoading, setIsFilterOrdersLoading] = useState(false);
  const [filteredOrderOptions, setFilteredOrderOptions] = useState([]);
  //const [sentMessageMutation] = useMutation(sentMessageGql);


  const CloseSentMessage = () => {
    onClose();
  }

  /*
  if (loading) {
    console.log('loading');
    return <p></p>;
  }
  if (error) return <p>Error getting data</p>; //error
  if (!data) return <p></p>; //loading
*/

  //const updateData = (field: string, data: any) => {
    //messageData[field] = data;
    //setMessageData(messageData);
  //};


  const updateText = (text) => {
  //  if (text !== "") {
      //  messageData.mailText = text;
      //  setMessageData(messageData);
  //  }
  };

  const handleNewAppointmentOrderChange = (value, item) => {
    //updateData('newAppointmentOrder', item);
  }

  const SentMessage = () => {
    /*
    onClose();

    const inputData = {
      orderId: orderId,
      messageType: messageType,
      mailText: messageData.mailText,
      mailSubject: messageData.mailSubject,
      sentMailToCustomer: messageData.sentMailToCustomer,
      sentMailToContactPerson: messageData.sentMailToContactPerson,
      sentMailToPhotograhyCompany: messageData.sentMailToPhotograhyCompany,
      sentMailToPhotograher: messageData.sentMailToPhotograher,
      sentSmsToCustomer: messageData.sentSmsToCustomer,
      sentSmsToContactPerson: messageData.sentSmsToContactPerson,
      sentSmsToPhotograhyCompany: messageData.sentSmsToPhotograhyCompany,
      sentSmsToPhotograher: messageData.sentSmsToPhotograher
    }

    sentMessageMutation({ variables: { input: inputData } }).then(() => {
      toast({
        title: "Beskeden er sendt.",
        description: "",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    })
      .catch(() => {
        toast({
          title: "Der opstod en fejl beskeden er ikke blevet sendt.",
          description: "Det var ikke muligt at sende beskeden.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
    */
  };


  if (!isVisible) {
    return null;
  }


  const onChangeInputHandler = (evt) => {
    setIsFilterOrdersLoading(true);

    console.log('LOADING CALENDER DATA: ', evt.target.value);
    client.query({
      query: SEARCH_ORDERS_GQL,
      variables: {
        search: evt.target.value
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    }).then((data) => {
      console.log('DATA', data);

      const orders = data.data.searchOrders;
      if (orders.length === 0) {
        setFilteredOrderOptions([]);
        setIsFilterOrdersLoading(false);
        return;
      }

      const mappedOrders = orders.map((order) => {
        return {
          id: order.id,
          caseNo: order.caseNo,
          address: order.estateAddress + ', ' + order.estateZipCode + ' ' + order.estateCity
        }
      });

      setFilteredOrderOptions(mappedOrders);
      setIsFilterOrdersLoading(false);

    });

  };

  const sentToOptions = [
    { value: 'sentMailToCustomer', label: 'Kunde' },
    { value: 'sentMailToContactPerson', label: 'Kontaktperson' },
    { value: 'sentMailToPhotograhyCompany', label: 'Fotografvirksomhed' },
    { value: 'sentMailToPhotograher', label: 'Fotograf' },
    { value: 'sentSmsToCustomer', label: 'SMS til kunde' },
    { value: 'sentSmsToContactPerson', label: 'SMS til kontaktperson' },
    { value: 'sentSmsToPhotograhyCompany', label: 'SMS til fotografvirksomhed' },
    { value: 'sentSmsToPhotograher', label: 'SMS til fotograf' }
  ]



  return (
    <Modal onClose={CloseSentMessage} size="xl" isOpen={true}>
      <ModalOverlay />
      <ModalContent maxW="900px">
        <ModalHeader>Send besked</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" style={{ width: "100%", height: "100%" }}>

            <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
              <CardHeader p="0px" mb="0px">
              </CardHeader>
              <CardBody px="5px" height={"100%"}>
                <Flex direction="column" style={{ width: "100%", height: "100%" }}>
                  <Flex align="center" mb="18px">
                    <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                      Til:{" "}
                    </Text>
                    <ChakraSelect
                      name="sentTo"
                      className="sentToSelector"
                      //defaultValue={selectedSubcontractorType()}
                      options={sentToOptions}
                      isMulti
                      placeholder="Vælg modtager..."
                      closeMenuOnSelect={false}
                    //onChange={(obj) => updateData('subcontractorType', obj.value)}
                    />
                  </Flex>
                  <Flex align="center" mb="18px">
                    <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                      Emne:{" "}
                    </Text>
                    <Input
                      
                      type="text"
                      name="mailSubject"
                      defaultValue={''}
                    //onBlur={(e) =>
                    //  updateData("mailSubject", e.target.value)
                    //}
                    />
                  </Flex>

                  <Flex align="center" mb="18px">
                    <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                      Ordre:{" "}
                    </Text>
       
                    <AutoComplete openOnFocus suggestWhenEmpty={false} isLoading={isFilterOrdersLoading} onChange={(value, item) => { handleNewAppointmentOrderChange(value, item) }}>
                      <AutoCompleteInput variant="filled" onChange={onChangeInputHandler} />
                      <AutoCompleteList>
                        {filteredOrderOptions.map((order, cid) => (
                          <AutoCompleteItem
                            key={`option-${cid}`}
                            value={order.id}
                            id={order.id}
                            label={order.caseNo + ' - ' + order.address}
                          >
                            {order.caseNo + ' - ' + order.address}
                          </AutoCompleteItem>
                        ))}
                      </AutoCompleteList>
                    </AutoComplete>
                  </Flex>

                  <Flex align="center" mb="18px">
                    <Editor value={''} changed={(e) => (updateText(e))} showFields={true} />
                  </Flex>
                </Flex>
              </CardBody>
            </Card>

          </Flex>
        </ModalBody >
        <ModalFooter>

          <Button onClick={CloseSentMessage}>Annuller</Button>
          <Button colorScheme='green' onClick={SentMessage} ml={3}>Send besked</Button>
        </ModalFooter>
      </ModalContent >
    </Modal >
  );
};

export default SentMessageDialog;
