// Chakra imports
import {
  Flex, Text, useColorModeValue, useToast, ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input
} from "@chakra-ui/react";
// Custom components
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import { gql,useLazyQuery, useMutation } from "@apollo/client";
import Editor from "../../../../components/Editor/Editor";
import { useEffect, useState } from "react";


const sentMessageGql = gql`
  mutation sentMessage($input: SentMessagesInput!) {
    sentMessage(input: $input)
  }
`;

const getMessagesGql = gql`
  query message($input: MessageInput!) {
    message(input: $input) {
      mailText
      mailSubject
      sentMailToCustomer
      sentMailToContactPerson
      sentMailToPhotograhyCompany
      sentMailToPhotograher
      sentSmsToCustomer
      sentSmsToContactPerson
      sentSmsToPhotograhyCompany
      sentSmsToPhotograher
    }
  }
`;

export enum MessageType {
  ORDER_RECEIVED = "ORDER_RECEIVED", // eslint-disable-line
  ORDER_CONFIRMATION = "ORDER_CONFIRMATION", // eslint-disable-line
  UNSUCCESSFULL_CONTACT_ATTEMPT = "UNSUCCESSFULL_CONTACT_ATTEMPT", // eslint-disable-line
  ORDER_BOOKED = "ORDER_BOOKED", // eslint-disable-line
  ORDER_CANCELED = "ORDER_CANCELED", // eslint-disable-line
  ORDER_REMINDER = "ORDER_REMINDER", // eslint-disable-line
  ORDER_DELIVERED = "ORDER_DELIVERED", // eslint-disable-line
  ORDER_WEATHER_UPDATE_BOOKED = "ORDER_WEATHER_UPDATE_BOOKED", // eslint-disable-line
  ORDER_SEASON_UPDATE_BOOKED = "ORDER_SEASON_UPDATE_BOOKED" // eslint-disable-line
}

interface IProps {
  orderId: String
  messageType: MessageType
  isVisible: boolean
  onClose: any
}

const OrderSentMessage: React.FC<IProps> = ({ orderId, messageType, onClose, isVisible }) => {

  const [getRenderedTexts, { loading, error, data }] = useLazyQuery(getMessagesGql, { fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });

  
/*
  const { loading, error, data } = useQuery(
    getMessagesGql,
    {
      variables: {
        input: { messageType: messageType, orderId: orderId },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      },
    }
  );
  */

  useEffect(() => {
    if (isVisible) {
      getRenderedTexts({ variables: { input: { messageType: messageType, orderId: orderId } } });
    }
  }, [isVisible]);

  const textColor = useColorModeValue("gray.700", "white");
  const toast = useToast();
  const [messageData, setMessageData] = useState(null);
  const [sentMessageMutation] = useMutation(sentMessageGql);

  const CloseSentMessage = () => {
    onClose();
  }

  if (loading) {
    console.log('loading');
    return <p></p>;
  }
  if (error) return <p>Error getting data</p>; //error
  if (!data) return <p></p>; //loading

  if (!data.message.sentMailToCustomer && !data.message.sentMailToContactPerson && !data.message.sentMailToPhotograhyCompany && !data.message.sentMailToPhotograher && !data.message.sentSmsToCustomer && !data.message.sentSmsToContactPerson && !data.message.sentSmsToPhotograhyCompany && !data.message.sentSmsToPhotograher) {
    console.log('No person to send message to');
    onClose();
  }

  const updateData = (field: string, data: any) => {
    messageData[field] = data;
    setMessageData(messageData);
  };


  const updateText = (text) => {
    if (text !== "") {
      messageData.mailText = text;
      setMessageData(messageData);
    }
  };

  const SentMessage = () => {

    onClose();

    const inputData = {
      orderId: orderId,
      messageType: messageType,
      mailText: messageData.mailText,
      mailSubject: messageData.mailSubject,
      sentMailToCustomer: messageData.sentMailToCustomer,
      sentMailToContactPerson: messageData.sentMailToContactPerson,
      sentMailToPhotograhyCompany: messageData.sentMailToPhotograhyCompany,
      sentMailToPhotograher: messageData.sentMailToPhotograher,
      sentSmsToCustomer: messageData.sentSmsToCustomer,
      sentSmsToContactPerson: messageData.sentSmsToContactPerson,
      sentSmsToPhotograhyCompany: messageData.sentSmsToPhotograhyCompany,
      sentSmsToPhotograher: messageData.sentSmsToPhotograher
    }

    sentMessageMutation({ variables: { input: inputData } }).then(() => {
      toast({
        title: "Beskeden er sendt.",
        description: "",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    })
      .catch(() => {
        toast({
          title: "Der opstod en fejl beskeden er ikke blevet sendt.",
          description: "Det var ikke muligt at sende beskeden.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });

  };

  if (messageData === null) {
    setMessageData({ ...data.message });
  }

  console.log('data', data.message);
  return (
    <Modal onClose={CloseSentMessage} size="xl" isOpen={true}>
      <ModalOverlay />
      <ModalContent maxW="700px">
        <ModalHeader>Send besked</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" style={{ width: "100%", height: "100%" }}>

            <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
              <CardHeader p="12px 5px" mb="12px">
                <Text fontSize="lg" color={textColor} fontWeight="bold"  >
                  Send besked angående ordren.
                </Text>
              </CardHeader>
              <CardBody px="5px" height={"100%"}>
                <Flex direction="column" style={{ width: "100%", height: "100%" }}>

                  <Flex align="center" mb="18px">
                    <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                      Emne:{" "}
                    </Text>
                    <Input
                      variant="flushed"
                      type="text"
                      name="mailSubject"
                      defaultValue={data.message.mailSubject}
                      onBlur={(e) =>
                        updateData("mailSubject", e.target.value)
                      }
                    />
                  </Flex>
                  <Flex align="center" mb="18px">
                    <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                      Sendes til:{" "}
                    </Text>
                    {data.message.sentMailToContactPerson && (
                      <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                        Kontaktperson (mail), 
                      </Text>
                    )}
                    {data.message.sentSmsToContactPerson && (
                      <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                        Kontaktperson (sms), 
                      </Text>
                    )}
                    {data.message.sentMailToCustomer && (
                      <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                        Mægler forretning (email) 
                      </Text>
                    )}
                    {data.message.sentSmsToCustomer && (
                      <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                        Mægler op ordre (sms) 
                      </Text>
                    )}
                    {data.message.sentMailToPhotograhyCompany && (
                      <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                        Fotograf firma (email) 
                      </Text>
                    )}
                    {data.message.sentMailToPhotograher && (
                      <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                        Fotograf (email) 
                      </Text>
                    )}
                    {data.message.sentSmsToPhotograhyCompany && (
                      <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                        Fotograf firma (sms) 
                      </Text>
                    )}

                    {data.message.sentSmsToPhotograher && (
                      <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                        Fotograf (sms) 
                      </Text>
                    )}




                    {/*}
                    <Checkbox defaultChecked={data.message.sentMailToCustomer ? true : false} onChange={(e) => updateData('sentMailToCustomer', e.target.checked)}>Mægler</Checkbox>
                    <Checkbox defaultChecked={messagetToContactPerson ? true : false} ml={3} onChange={(e) => updateData('sentMailToContactPerson', e.target.checked)}>Kontaktperson</Checkbox>
                    {*/}

                  </Flex>
                  <Flex align="center" mb="18px">
                    <Editor value={data.message.mailText} changed={(e) => (updateText(e))} showFields={false} />
                  </Flex>
                </Flex>
              </CardBody>
            </Card>

          </Flex>
        </ModalBody>
        <ModalFooter>

          <Button onClick={CloseSentMessage}>Annuller</Button>
          <Button colorScheme='green' onClick={SentMessage} ml={3}>Send besked</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderSentMessage;
